<template>
  <th-wrapper
    class="m-8 systems-form"
    :title="$t('common.titles.general_info.title')"
    :subtitle="$t('pages.voucher_systems.form.system.description')"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="20">
        <!-- Name -->
        <el-col :md="8">
          <el-form-item prop="name" :label="$t('common.forms.labels.name')">
            <el-input
              id="name"
              v-model="form.name"
              :placeholder="$t('common.forms.labels.name')"
            />
          </el-form-item>
        </el-col>
        <!-- Available in -->
        <el-col :md="8">
          <el-form-item
            for="locations"
            :label="$t('common.forms.labels.locations')"
          >
            <available-in
              id="locations"
              v-model="locations"
              :resources="resources"
              :show-items-limit="2"
            />
          </el-form-item>
        </el-col>
        <!-- Description -->
        <el-col :md="8">
          <el-form-item
            prop="description"
            class="h-10"
            :label="$t('common.forms.labels.comment')"
          >
            <el-input
              id="description"
              v-model="form.description"
              :placeholder="$t('common.forms.labels.comment')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="flex justify-between">
          <!-- Is campaign -->
          <el-form-item prop="is_campaign" class="mb-0">
            <div class="flex items-center">
              <th-popover
                :text="$t('pages.voucher_systems.form.is_campaign.tooltip')"
                class="mr-2"
              />
              <el-checkbox id="is_campaign" v-model="isCampaign">
                {{ $t('pages.voucher_systems.form.is_campaign.label') }}
              </el-checkbox>
            </div>
          </el-form-item>
          <!-- Active -->
          <el-form-item prop="active" class="mb-0">
            <el-checkbox
              id="active"
              v-model="form.active"
              :aria-label="$t('common.forms.labels.active')"
              :label="$t('common.forms.labels.active')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import AvailableIn from '@/components/available-in'

export default {
  components: {
    AvailableIn
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    locations: {
      get() {
        return {
          locations: this.form.branches, //component uses locations but voucher-system still support legacy branches
          branch_groups: this.form.branch_groups
        }
      },
      set(value) {
        this.form.branches = value.locations
        this.form.branch_groups = value.branch_groups
      }
    },
    isCampaign: {
      get() {
        return safeGet(this.form, 'templates.0.attributes.is_campaign', false)
      },
      set(value) {
        safeSet(this.form, 'templates.0.attributes.is_campaign', value)
      }
    },
    rules() {
      return {
        name: {
          required: true,
          message: this.$t('common.forms.rules.field_warnings.required'),
          trigger: ['blur', 'change']
        },
        description: {
          max: 1024,
          message: this.$t('common.forms.rules.max_length', { max: 1024 }),
          trigger: ['blur', 'change']
        }
      }
    }
  },
  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
