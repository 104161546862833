<template>
  <el-row :gutter="20" class="flex items-center mb-3">
    <!-- Amount -->
    <el-form-item
      :label="amountLabel"
      :prop="`increment-${index}`"
      class="mb-0"
    >
      <th-currency-input
        :id="`increment-${index}`"
        v-model="localValue.value"
        class="w-full"
        :currency="localValue.currency"
      />
    </el-form-item>

    <!-- Delete -->
    <el-button
      icon="Delete"
      class="mx-2 el-button--text-icon"
      :class="{ 'mt-th-input-title': isFirstRow }"
      :title="$t('common.interactions.buttons.remove')"
      @click="$emit('remove-item')"
    />

    <!-- Add -->
    <el-button
      v-if="showAddButton"
      :title="$t('common.interactions.buttons.add')"
      plain
      class="el-button--primary-icon ml-0"
      :class="{ 'mt-th-input-title': isFirstRow }"
      icon="Plus"
      @click="$emit('add-item')"
    />
  </el-row>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    showAddButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    isFirstRow() {
      return this.index === 0
    },
    amountLabel() {
      return this.isFirstRow
        ? this.$t('pages.voucher_systems.form.settings.fix_value')
        : null
    }
  }
}
</script>
