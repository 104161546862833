<template>
  <th-wrapper
    class="m-8"
    :title="$t('pages.voucher_systems.form.settings.label')"
    :subtitle="$t('pages.voucher_systems.form.settings.description')"
  >
    <el-form ref="form" :model="form">
      <el-row :gutter="20">
        <el-col :md="12" class="ml-3">
          <increment-row
            v-for="(item, index) in form.increments"
            :key="index"
            v-model="form.increments[index]"
            :index="index"
            :show-add-button="index === form.increments.length - 1"
            @add-item="addFixAmount"
            @remove-item="removeFixAmount(index)"
          />
        </el-col>
        <el-col :md="8">
          <min-max-values v-model="template" />
        </el-col>
      </el-row>
      <hr class="my-4" />
      <el-row :gutter="20">
        <voucher-format ref="voucher-format" v-model="template" />
      </el-row>
      <el-row v-show="!isCampaign" :gutter="20">
        <attribute-settings v-model="template" />
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import get from 'just-safe-get'
import set from 'just-safe-set'
import { genInitialFixedValue } from '../../helpers'
import IncrementRow from './increment-row'
import MinMaxValues from './min-max-values'
import AttributeSettings from './attribute-settings'
import VoucherFormat from './voucher-format'

export default {
  components: { IncrementRow, MinMaxValues, AttributeSettings, VoucherFormat },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    template: {
      get() {
        return get(this.form, 'templates.0', {})
      },
      set(value) {
        set(this.form, 'templates.0', value)
      }
    },
    isCampaign() {
      return get(this.template, 'attributes.is_campaign', false)
    }
  },
  methods: {
    addFixAmount() {
      this.form.increments.push(genInitialFixedValue())
    },
    removeFixAmount(index) {
      this.form.increments.splice(index, 1)
      if (this.form.increments.length === 0) this.addFixAmount()
    },
    validate() {
      return this.$refs['voucher-format'].validate()
    }
  }
}
</script>
