<template>
  <el-form
    ref="form"
    :model="modelValue"
    :rules="rules"
    class="flex items-center"
  >
    <!-- Barcode generated by -->
    <el-col :md="8">
      <el-form-item
        prop="code_generation"
        :label="$t('pages.voucher_systems.form.settings.code_generation')"
      >
        <el-select v-model="localValue.code_generation" class="w-full">
          <el-option
            v-for="type in codeGenerationTypes"
            :key="type.value"
            :value="type.value"
            :label="type.label"
          />
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :md="8">
      <!-- Format type -->
      <el-form-item
        prop="attributes.format_type"
        :label="$t('pages.voucher_systems.form.settings.format_type')"
      >
        <el-select
          id="attributes.format_type"
          v-model="localValue.attributes.format_type"
          class="w-full"
        >
          <el-option
            v-for="item in availableFormatTypes"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :md="8">
      <!-- Format -->
      <el-form-item
        class="w-full"
        prop="attributes.format"
        :label="$t('pages.voucher_systems.form.settings.format')"
      >
        <el-input
          id="attributes.format"
          v-model="localValue.attributes.format"
          :placeholder="
            $t('pages.voucher_systems.form.settings.format_placeholder')
          "
          @input="setFormat"
        />
      </el-form-item>
    </el-col>
  </el-form>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    rules() {
      return {
        attributes: {
          format: {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.format.validation.errors.required'
            ),
            trigger: 'blur'
          },
          format_type: {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.format.validation.errors.required'
            ),
            trigger: ['blur', 'change']
          }
        },
        code_generation: [
          {
            required: true,
            message: this.$t(
              'pages.vouchers.edit.form.name.validation.errors.required'
            ),
            trigger: 'change'
          }
        ]
      }
    },
    translations() {
      return {
        scan: this.$t('pages.vouchers.edit.form.code_generation.types.scan'),
        request: this.$t(
          'pages.vouchers.edit.form.code_generation.types.request'
        )
      }
    },
    availableFormatTypes() {
      return ['alphanumeric', 'numeric', 'alphabetic']
    },
    codeGenerationTypes() {
      return ['scan', 'request'].map((value) => {
        return { value, label: this.translations[value] }
      })
    }
  },
  methods: {
    setFormat(value) {
      this.localValue.attributes.format = value.replace(/[^-\s]/g, 'X')
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    }
  }
}
</script>
