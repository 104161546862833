<template>
  <el-row class="flex items-center">
    <!-- Increment amount minimum -->
    <el-form-item
      :label="
        $t('pages.voucher_systems.form.settings.increment_amount_minimum')
      "
      prop="increment_amount_minimum"
      class="mr-4"
    >
      <th-currency-input
        id="increment_amount_minimum"
        v-model="localValue.attributes.increment_amount_minimum"
        class="w-full"
        :placeholder="
          $t('pages.voucher_systems.form.settings.value_placeholder', {
            value: $formatCurrency(5, localValue.attributes.currency)
          })
        "
        :currency="localValue.attributes.currency"
      />
    </el-form-item>
    <!-- Amount max -->
    <el-form-item
      :label="$t('pages.voucher_systems.form.settings.amount_max')"
      prop="amount_max"
    >
      <th-currency-input
        id="amount_max"
        v-model="localValue.attributes.amount_max"
        class="w-full"
        :placeholder="
          $t('pages.voucher_systems.form.settings.value_placeholder', {
            value: $formatCurrency(100, localValue.attributes.currency)
          })
        "
        :currency="localValue.attributes.currency"
      />
    </el-form-item>
  </el-row>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  }
}
</script>
