import * as uuid from 'uuid'
import { getDefaultCurrency } from '@/utils/general'

export const ACTION_TYPES = ['decrement', 'increment', 'create']

export function generateDefault() {
  return {
    actions: ACTION_TYPES.map((type) => genInitialAction(type)),
    active: true,
    branch_groups: null,
    branches: null,
    charge_valid_from: null,
    country: null,
    description: null,
    external_system_id: null,
    external_system_type: null,
    external_voucher_system_id: null,
    external: false,
    hooks: null,
    increments: [genInitialFixedValue()],
    locations: null,
    metadata: null,
    name: null,
    product: null,
    region: null,
    scan_prefixes: [],
    validity_period: genValidityPeriod(1),
    templates: [genInitialTemplate()]
  }
}

export function genInitialTemplate(currency) {
  return {
    id: uuid.v4(),
    name: 'default',
    code_generation: 'request',
    attributes: {
      active: true,
      amount_max: null,
      amount: null,
      charge_valid_from: null,
      comment: null,
      constraints: genInitialConstrains(),
      currency: currency || getDefaultCurrency(),
      discount_rate: null,
      exchange_for_cash: false,
      expires_at: null,
      format_type: null,
      format: null,
      increment_amount_minimum: null,
      is_campaign: false,
      partial_redemption: true,
      product: null,
      redemption_valid_from: null,
      refundable: true,
      regions: [],
      restriction_single_transaction: false,
      type: 'amount'
    }
  }
}

export function genInitialAction(type = null) {
  return {
    id: uuid.v4(),
    name: type,
    type,
    lookup: 'code',
    product: null
  }
}

export function genInitialFixedValue(currency) {
  return {
    type: 'amount',
    value: null,
    currency: currency || getDefaultCurrency()
  }
}

export function genValidityPeriod(value = null) {
  return { unit: 'years', value }
}

export function genInitialConstrains() {
  return {
    time: {
      scheduled: {
        day_of_week: {
          enabled: false
        }
      }
    }
  }
}
