<template>
  <div v-loading="loading">
    <!-- System form -->
    <system-form ref="system-form" v-model="current" :resources="resources" />
    <!-- Actions form -->
    <actions-form ref="actions-form" v-model="current" />
    <!-- Settings form -->
    <settings-form ref="settings-form" v-model="current" />
    <!-- Validity form -->
    <validity-form ref="validity-form" v-model="current" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import voucherSystemsModel from '../model/voucher-systems-model'
import { generateDefault } from '../helpers'
import SystemForm from './system/form'
import ActionsForm from './actions/form'
import SettingsForm from './settings/form'
import ValidityForm from './validity/form'

export default {
  name: 'VoucherSystemsForm',
  components: {
    SystemForm,
    ActionsForm,
    SettingsForm,
    ValidityForm
  },
  props: {
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    return voucherSystemsModel.setup(generateDefault())
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    })
  },
  async mounted() {
    this.id = this.$route.params.id
    const { data = {} } = (await this.fetch()) || {}
    // `type` is not part of the form but is part of the response, so we'll get it directly.
    this.$emit('is-abocard', data.type === 'abocard')
  },
  methods: {
    async submitForm() {
      const systemFormValid = await this.validate('system-form')
      const actionsFormValid = await this.validate('actions-form')
      const settingsFormValid = await this.validate('settings-form')
      const validityFormValid = await this.validate('validity-form')
      if (
        !(
          systemFormValid &&
          actionsFormValid &&
          settingsFormValid &&
          validityFormValid
        )
      ) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }
      const isNew = this.isNew
      const resource = this.$t('common.resource.voucher_system.singular')
      const { error } = await this.save()

      //handle error
      if (error) {
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })
        return this.$logException(error, {
          trackError: true,
          message: errorMessage
        })
      }

      //handle success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })
      this.$message({
        type: 'success',
        message: successMessage
      })

      if (isNew) {
        if (this.navigationAfterCreation === 'edit') {
          this.routeTo(this.id)
        } else {
          this.routeTo()
        }
      }
    },
    async validate(formName) {
      const validator = this.$refs[formName]?.validate
      if (validator) {
        return await validator()
      }
      return true
    },
    routeTo(path) {
      if (!path) return this.$router.push({ name: 'voucher-systems-list' })
      this.$router.push({ name: 'voucher-systems-edit', params: { id: path } })
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.name || this.id)
      if (confirm) this.delete()
    },
    async delete() {
      const { error } = await this.deleteModel()

      //handle error
      if (error) {
        return this.$logException(error, {
          trackError: true,
          message: this.$t('common.error.action.delete.single', {
            resource: this.$t('common.resource.voucher_system.singular')
          })
        })
      }

      //handle success
      this.reset()
      this.routeTo()
    }
  }
}
</script>
