import baseModel from '@/models/base-model-new'
import merge from 'deepmerge'

import {
  genInitialAction,
  genInitialTemplate,
  genInitialFixedValue,
  genInitialConstrains,
  genValidityPeriod,
  ACTION_TYPES
} from '../helpers'

export default {
  setup(model) {
    const methods = {
      parseResponse(response) {
        //add in order actions. initialize action if none.
        const data = response?.data || {}
        const actions = ACTION_TYPES.map((type) => {
          const item = data.actions?.find((action) => action.type === type)
          return item || genInitialAction(type)
        })

        //sort increments in order of value small to large
        data.increments?.sort((a, b) => a.value - b.value)

        //Add template if template was not created
        const templates = [...(data.templates || [])]
        templates[0] = merge(genInitialTemplate(), templates[0] || {})

        if (!data.validity_period) {
          data.validity_period = genValidityPeriod()
        }

        // init increments
        if (!data.increments?.length) {
          data.increments = [genInitialFixedValue()]
        }

        // init constraints
        if (!data.constraints?.time?.scheduled) {
          data.constraints = genInitialConstrains()
        }

        return { ...data, actions, templates }
      },
      parseDataBeforeSave(data) {
        //remove empty value items
        data.increments = data.increments.filter(({ value }) =>
          Number.isFinite(value)
        )

        //remove validity_period if an end-date is set or if we want it to last forever
        if (
          data.templates[0]?.attributes?.expires_at ||
          !data.validity_period?.value
        ) {
          data.validity_period = null
        }

        return data
      }
    }
    return baseModel.setup(model, 'voucherSystems', methods)
  }
}
